import { Pagination, PaginationParams, StemPalette } from 'src/common/common.interface'
import { getHttp, postHttp, putHttp } from './apiLayer'

type CreateStemPalettePayload = {
  name: string
  colorPaletteId: number
  colorSwatch: string[]
  stems: number[]
}

type UpdatePaletteStems = {
  name?: string
  colorPaletteId?: number
  colorSwatch?: string[]
  paletteStems?: {
    id?: number
    isDeleted?: boolean
    stemId: number
  }[]
}

export const checkExistedStemPaletteName = async (name: string, excludeIds?: number[]): Promise<boolean> => {
  const result = await postHttp(`stem-palettes/check-existed-name`, { name, excludeIds })
  return result.data
}

export const createStemPalette = async (payload: CreateStemPalettePayload): Promise<boolean> => {
  const result = await postHttp(`stem-palettes`, payload)
  return result.data
}

export const getListStemPalettes = async (params: PaginationParams): Promise<Pagination<StemPalette[]>> => {
  const result = await getHttp(`stem-palettes`, { params })
  return result.data
}

export const getStemPalette = async (paletteId: number): Promise<StemPalette> => {
  const result = await getHttp(`stem-palettes/${paletteId}`)
  return result.data
}

export const updatePaletteStems = async (paletteId: number, payload: UpdatePaletteStems): Promise<StemPalette> => {
  const result = await putHttp(`stem-palettes/${paletteId}`, payload)
  return result.data
}
