import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StemPalette } from 'src/common/common.interface'

type StemPaletteState = {
  palettes: StemPalette[]

  currentPalette: StemPalette | null
}

const initialState: StemPaletteState = {
  palettes: [],
  currentPalette: null
}

const stemPaletteSlice = createSlice({
  name: 'stem-palette',
  initialState,
  reducers: {
    setPalettes: (state, action: PayloadAction<StemPalette[]>) => {
      state.palettes = action.payload
    },
    setCurrentPalette: (state, action: PayloadAction<StemPalette | null>) => {
      state.currentPalette = action.payload
      const index = state.palettes.findIndex((p) => p.id === action.payload?.id)
      if (index > -1 && action.payload) {
        state.palettes[index] = action.payload
      }
    }
  }
})

export const stemPaletteAction = stemPaletteSlice.actions

export default stemPaletteSlice.reducer
