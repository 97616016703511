/* eslint-disable @typescript-eslint/no-use-before-define */
import { createAction } from '@reduxjs/toolkit'
import { takeEvery, takeLatest, call, all, put, debounce } from 'redux-saga/effects'
import {
  getListProducts,
  getDetailProduct,
  IGetProductPayload,
  getRecipesByProduct,
  IGetRecipesByProductResponse,
  saveProductToCatalog
} from 'src/api/products'
import { getAllProductTypes, ProductType } from 'src/api/productType'

import { Product, ProductCategory } from 'src/common/common.interface'

import { productAction } from './product.slice'

export function* getProductTypesSaga() {
  try {
    const productTypes: ProductType[] = yield call(getAllProductTypes)

    yield put(productAction.setProductTypes(productTypes))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getProductTypesSaga', error)
  }
}

export function* getProductCategoriesSaga() {
  try {
    const productCategories: ProductCategory[] = []

    yield put(productAction.setProductCategoriesData(productCategories))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getStemCategoriesSaga', error)
  }
}

export function* getProductsSaga({ payload }: ReturnType<typeof productActionSaga.getListProducts>) {
  try {
    const products: { result: Product[]; count: number } = yield call(getListProducts, payload)
    yield put(productAction.setProductsData(products?.result))
    yield put(productAction.setCountProduct(products?.count))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getStemsSaga', error)
  }
  yield put(productAction.setLoadingGetListProducts(true))
}

export function* getMoreProductsSaga({ payload }: ReturnType<typeof productActionSaga.getListProducts>) {
  yield put(productAction.setLoadingMore(true))
  try {
    const products: { result: Product[] } = yield call(getListProducts, payload)
    yield put(productAction.addMoreProductsData(products?.result))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getStemsSaga', error)
  }
  yield put(productAction.setLoadingMore(false))
}

export function* getDetailProductSaga({ payload: productId }: ReturnType<typeof productActionSaga.getDetailProduct>) {
  try {
    const product: Product = yield call(getDetailProduct, productId)

    yield put(productAction.setProductData(product))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getDetailProductSaga', error)
  }
}

export function* getRecipesByProductSaga({
  payload: productId
}: ReturnType<typeof productActionSaga.getRecipesByProduct>) {
  try {
    const recipeTemplate: IGetRecipesByProductResponse = yield call(getRecipesByProduct, productId)

    yield put(productAction.setRecipeTemplateData(recipeTemplate))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error getDetailProductSaga', error)
  }
}

export function* saveProductToCatalogSaga({
  payload: productId
}: ReturnType<typeof productActionSaga.saveProductToCatalog>) {
  try {
    yield call(saveProductToCatalog, productId)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error saveProductToCatalogSaga', error)
  }
}

export const productActionSaga = {
  getProductTypes: createAction('productActionSaga/getProductTypes'),
  getProductCategories: createAction('productActionSaga/getProductCategories'),
  getListProducts: createAction<IGetProductPayload>('productActionSaga/getListProducts'),
  getMoreProducts: createAction<IGetProductPayload>('productActionSaga/getMoreProducts'),
  getDetailProduct: createAction<number>('productActionSaga/getDetailProduct'),
  getRecipesByProduct: createAction<number>('productActionSaga/getRecipesByProduct'),
  saveProductToCatalog: createAction<number>('productActionSaga/saveProductToCatalog')
}

export default [
  function* fetchWatcher() {
    yield all([
      takeEvery(productActionSaga.getProductTypes, getProductTypesSaga),
      takeEvery(productActionSaga.getProductCategories, getProductCategoriesSaga),
      takeLatest(productActionSaga.getDetailProduct, getDetailProductSaga),
      takeLatest(productActionSaga.getRecipesByProduct, getRecipesByProductSaga),
      takeLatest(productActionSaga.getMoreProducts, getMoreProductsSaga),
      takeLatest(productActionSaga.saveProductToCatalog, saveProductToCatalogSaga)
    ])
    yield all([debounce(500, productActionSaga.getListProducts, getProductsSaga)])
  }
]
