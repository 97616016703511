import {
  ICreateRecipePayload,
  Product,
  ProductImage,
  ProjectImageAttribution,
  RecipeModel,
  RecipeTemplate
} from 'src/common/common.interface'
import { PhotosNeeded } from 'src/common/enum'
import { getHttp, putHttp, postHttp } from './apiLayer'

export interface RecipesPayload {
  unitProductId: number
  eventOrderId: number
}

export enum PRICE_UNIT_TYPE {
  TABLE = 'TABLE',
  GUEST = 'GUEST',
  ITEM = 'ITEM',
  SQ_FT = 'SQ_FT'
}

export enum PRODUCT_IMAGE_TYPE {
  DEFAULT = 'DEFAULT',
  INSPO = 'INSPO',
  RESULT = 'RESULT'
}

export enum PRODUCT_DETAIL_TYPE {
  BOUQ_BR = 'BOUQ_BR',
  BOUQ_BM = 'BOUQ_BM',
  BOUT = 'BOUT',
  PERSONAL = 'PERSONAL',
  CEREMONY = 'CEREMONY',
  RECEPTION = 'RECEPTION',
  COCKTAIL = 'COCKTAIL',
  RENTAL = 'RENTAL',
  ARCH_CLUSTER = 'ARCH_CLUSTER',
  BUD_VASE = 'BUD_VASE',
  CENTERPIECE = 'CENTERPIECE',
  CLOUD = 'CLOUD',
  CORSAGE = 'CORSAGE',
  OTHER = 'OTHER',
  SMALL_BRIDAL_BOUQUET = 'SMALL_BRIDAL_BOUQUET',
  MEDIUM_BRIDAL_BOUQUET = 'MEDIUM_BRIDAL_BOUQUET',
  LARGE_BRIDAL_BOUQUET = 'LARGE_BRIDAL_BOUQUET',
  CASCADING_BOUQUET = 'CASCADING_BOUQUET',
  SMALL_BRIDESMAID_BOUQUET = 'SMALL_BRIDESMAID_BOUQUET',
  MEDIUM_BRIDESMAID_BOUQUET = 'MEDIUM_BRIDESMAID_BOUQUET',
  LARGE_BRIDESMAID_BOUQUET = 'LARGE_BRIDESMAID_BOUQUET',
  POCKET_BOUTONNIERE = 'POCKET_BOUTONNIERE',
  LAPEL_BOUTONNIERE = 'LAPEL_BOUTONNIERE',
  PIN_CORSAGE = 'PIN_CORSAGE',
  CUFF_CORSAGE = 'CUFF_CORSAGE',
  PARTIAL_ARCH_COVERAGE = 'PARTIAL_ARCH_COVERAGE',
  FULL_ARCH_COVERAGE = 'FULL_ARCH_COVERAGE',
  COMPOTE_CENTERPIECE = 'COMPOTE_CENTERPIECE',
  DOG_COLLAR = 'DOG_COLLAR',
  SMALL_FLOWER_CLOUD = 'SMALL_FLOWER_CLOUD',
  MEDIUM_FLOWER_CLOUD = 'MEDIUM_FLOWER_CLOUD',
  LARGE_FLOWER_CLOUD = 'LARGE_FLOWER_CLOUD',
  FLAT_LAY_FLOWERS = 'FLAT_LAY_FLOWERS',
  SMALL_ARCH_CLUSTER = 'SMALL_ARCH_CLUSTER',
  MEDIUM_ARCH_CLUSTER = 'MEDIUM_ARCH_CLUSTER',
  LARGE_ARCH_CLUSTER = 'LARGE_ARCH_CLUSTER',
  CHUPPAH = 'CHUPPAH'
}

export enum PRODUCT_DETAIL_SHAPE {
  CLASSIC = 'CLASSIC',
  LUSH_GARDEN = 'LUSH_GARDEN',
  ASYMMETRICAL = 'ASYMMETRICAL',
  CASCADING = 'CASCADING'
}

export enum PRODUCT_SEASON {
  SUMMER = 'Summer',
  SPRING = 'Spring',
  FALL = 'Fall',
  WINTER = 'Winter'
}

export interface CreateCustomProductPayload {
  name: string
  productType: PRODUCT_DETAIL_TYPE[]
  price?: number
  priceUnit?: PRICE_UNIT_TYPE
  priceUnitDisplay?: string
  productColor?: string[]
  productShape?: PRODUCT_DETAIL_SHAPE
  productSeason?: PRODUCT_SEASON[]
  imageUrl: string
  productNotes?: string
  publicNotes?: string
  defaultPrepTimeMinutes?: number
  defaultDesignTimeMinutes?: number
  defaultInstallTimeMinutes?: number
  defaultLoadingTimeMinutes?: number
  defaultInstructions?: string
  defaultPhotosNeeded?: PhotosNeeded[]
  attribution?: ProjectImageAttribution
}

export interface CreateProductPayload {
  name: string
  productType: PRODUCT_DETAIL_TYPE[]
  price?: number
  priceUnit?: PRICE_UNIT_TYPE
  priceUnitDisplay?: string
  productColor?: string[]
  productShape?: PRODUCT_DETAIL_SHAPE
  productSeason?: PRODUCT_SEASON[]
  imageUrl: string
  productNotes?: string
  publicNotes?: string
  defaultPrepTimeMinutes?: number
  defaultDesignTimeMinutes?: number
  defaultInstallTimeMinutes?: number
  defaultLoadingTimeMinutes?: number
  defaultInstructions?: string
  defaultPhotosNeeded?: PhotosNeeded[]
  attribution?: ProjectImageAttribution
}

export interface UpdateProductPayload extends CreateProductPayload {
  // a case using
  onlyUpdateDefaultImage?: boolean
  // a case using
  onlyUpdateChangeResultImageToInspoImage?: boolean
  // a case using
  onlyUpdateEventToProductImage?: boolean
  eventId?: number
  //
  // a case using
  onlyUpdateInspoImageAsDefaultImage?: boolean
  // a case using
  onlyRemoveInspoImage?: boolean
  // a case using
  onlyUpdateDefaultImageByCropped?: boolean
}

export type UpdateProduct = Omit<UpdateProductPayload, 'name' | 'productType' | 'imageUrl'>

export interface IGetProductPayload {
  q?: string
  cat?: string
  color?: string
  template?: string
  stem?: string
  page?: number
  limit?: number
  isAll?: boolean
  isReview?: boolean
  isDuplicate?: string
  isDoNotAdd?: string
  recipe?: string
  shape?: string
  keystoneTag?: string
}

export interface IGetRecipesByProductResponse {
  recipeTemplate: RecipeTemplate
  recipe: RecipeModel[]
  recipeCount: number
}

export interface UpdateProductImagePayload {
  productImages: ProductImage[]
}

export interface ICreateNewCustomProductWithRecipeTemplatePayload {
  productPayload: CreateCustomProductPayload
  recipe?: ICreateRecipePayload
  eventId: number
}

export interface ICreateNewProductWithRecipeTemplatePayload {
  productPayload: CreateProductPayload
  recipe?: ICreateRecipePayload
}

export interface IUpdateProductWithRecipeTemplatePayload {
  productPayload: UpdateProductPayload
  recipe?: ICreateRecipePayload
}

export const getProducts = async (): Promise<Product[]> => {
  const result = await getHttp('/getProducts?limit=1000')
  return result.data?.result || []
}

export const getListProducts = async (payload: IGetProductPayload): Promise<Product[]> => {
  const params = { ...payload }
  params.q = encodeURIComponent(payload.q || '')
  params.isDuplicate = payload.isDuplicate ? 'is-duplicate' : ''
  params.isDoNotAdd = payload.isDoNotAdd ? 'do-not-add' : ''

  const result = await getHttp(`/getProducts`, { params })
  return result.data

  // if (payload.isAll) {
  //   const result = await getHttp(
  //     `/getProducts?q=${encodeURIComponent(payload.q || '')}&cat=${payload.cat || ''}&color=${
  //       payload.color || ''
  //     }&isAll=${true}&isReview=${payload.isReview}`
  //   )
  //   return result.data
  // }
  // const result = await getHttp(
  //   `/getProducts?q=${encodeURIComponent(payload.q || '')}&cat=${payload.cat || ''}&color=${
  //     payload.color || ''
  //   }&keystoneTag=${payload.keystoneTag || ''}&template=${payload.template || ''}&stem=${payload.stem || ''}&isReview=${
  //     payload.isReview
  //   }&isDuplicate=${payload.isDuplicate ? 'is-duplicate' : ''}&isDoNotAdd=${
  //     payload.isDoNotAdd ? 'do-not-add' : ''
  //   }&recipe=${payload.recipe || ''}&shape=${payload.shape || ''}&isAll=${true}`
  // )
  // return result.data
}

export const getEventProducts = async (eventId: number) => {
  const result = await getHttp(`events/${eventId}/products`)
  return result.data
}

export const createCustomsProduct = async (eventId: number, payload: CreateCustomProductPayload): Promise<Product> => {
  const result = await postHttp(`events/${eventId}/products/customs`, payload)
  return result.data
}

export const createProduct = async (payload: CreateProductPayload): Promise<Product> => {
  const result = await postHttp(`products`, payload)
  return result.data
}

export const updateProduct = async (productId: number, payload: UpdateProductPayload): Promise<Product> => {
  const result = await putHttp(`products/${productId}`, payload)
  return result.data
}

export const getDetailProduct = async (productId: number): Promise<Product> => {
  const result = await getHttp(`products/${productId}`)
  return result.data
}

export const getRecipesByProduct = async (productId: number): Promise<IGetRecipesByProductResponse> => {
  const result = await getHttp(`products/${productId}/recipes`)
  return result.data
}

export const saveProductToCatalog = async (productId: number): Promise<Product> => {
  const result = await putHttp(`products/${productId}/save-to-catalog`)
  return result.data
}

export const saveProductResultOrInspoImages = async (
  productId: number,
  payload: UpdateProductImagePayload
): Promise<ProductImage[]> => {
  const result = await postHttp(`products/${productId}/result-inspo-images`, payload)
  return result.data
}

export const markAsDuplicateProduct = async (oldProductId: number, newProductId: number): Promise<Product> => {
  const result = await putHttp(`products/${oldProductId}/mark-as-duplicate/${newProductId}`)
  return result.data
}

export const markAsDoNotAddProduct = async (productId: number): Promise<Product> => {
  const result = await putHttp(`products/${productId}/mark-as-do-not-add`)
  return result.data
}

export const removeProductFromCatalog = async (productId: number): Promise<Product> => {
  const result = await putHttp(`products/${productId}/remove-from-catalog`)
  return result.data
}

export const createCustomProductWithRecipeTemplate = async (
  eventId: number,
  payload: ICreateNewCustomProductWithRecipeTemplatePayload
): Promise<Product> => {
  const result = await postHttp(`events/${eventId}/products/custom-with-recipe-templates`, payload)
  return result.data
}

export const createProductWithRecipeTemplate = async (
  payload: ICreateNewProductWithRecipeTemplatePayload
): Promise<Product> => {
  const result = await postHttp(`products/with-recipe-templates`, payload)
  return result.data
}

export const updateProductWithRecipeTemplate = async (
  productId: number,
  payload: IUpdateProductWithRecipeTemplatePayload
): Promise<Product & { recipeTemplate: RecipeTemplate }> => {
  const result = await putHttp(`products/${productId}/with-recipe-templates`, payload)
  return result.data
}
