export const GENERIC_SUPPLIER = 'Generic'
export const PAGE_LIMIT = 20
export const LIMIT_FILE_UPLOAD_SIZE = 4194304

export enum HttpCustomResponseCode {
  DUPLICATE_PRODUCT_RECIPE_TEMPLATE = 'DUPLICATE_PRODUCT_RECIPE_TEMPLATE'
}

export const STEM_TOTAL_WARNING_PERCENT = 15
export const HARDGOOD_TOTAL_WARNING_PERCENT = 6
export const FREIGHT_TOTAL_WARNING_PERCENT = 6
export const COMBINED_TOTAL_WARNING_PERCENT = 27

export const ACCEPTED_IMAGE_FORMATS = ['.png', '.jpg', '.jpeg', '.heic', '.heif']
export const ACCEPTED_PDF_FORMATS = ['.pdf']

export const CAN_NOT_CREATE_SHIP_STATION_ORDER = 'CAN_NOT_CREATE_SHIP_STATION_ORDER'
