export const PATH_URL = {
  HOME: '/',
  EVENT: (eventId?: number) => `/event/${eventId ?? ':eventId'}`,
  ADD_PRODUCT: (eventId?: number) => `/add-product/${eventId ?? ':eventId'}`,
  PROFILE: '/profile',
  ORDER_SUMMARY: '/order-summary',
  TEMPLATE: '/template',
  PROPOSAL_TEMPLATE: '/proposal-templates',
  TEMPLATE_PRODUCT: (templateId?: number) => `/proposal-templates/${templateId ?? ':templateId'}`,
  STEM_PALETTE_STEM: (paletteId?: number) => `/stem-palettes/${paletteId ?? ':paletteId'}`,
  STEM_PALETTE: '/stem-palettes',
  STEMS: '/stems',
  PRODUCTS: '/products',
  REVIEW_PRODUCTS: '/review-products',
  PRODUCT_DETAIL: (productId?: number) => `/products/${productId ?? ':productId'}`,
  REVIEW_PRODUCT_DETAIL: (productId?: number) => `/review-products/${productId ?? ':productId'}`,
  CREATE_ORDER: (eventId?: number) => `/order/${eventId ?? ':eventId'}`,
  SUPPLIER_ORDER: (eventId?: number) => `/order/${eventId ?? ':eventId'}/supplierOrders`,
  EVENT_SUMMARY: (eventId?: number) => `/event-summary/${eventId ?? ':eventId'}`,
  EVENT_SUMMARY_OLD: (eventId?: number) => `/event-summary-old/${eventId ?? ':eventId'}`,
  HARD_GOODS: '/hard-goods',
  STYLE_INFORMATION: (eventId?: number) => `/style-information/${eventId ?? ':eventId'}`,
  DESIGN_GUIDE: (eventId?: number) => `/design-guide/${eventId ?? ':eventId'}`,
  // Notification Task feature
  NOTIFICATION_TASK: '/notification-task',
  // other common pages
  NOT_FOUND_PAGE: '/404'
}
