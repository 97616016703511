import { combineReducers } from '@reduxjs/toolkit'
import recipeReducer from './recipe/recipe.slice'
import appReducer from './app/app.slice'
import orderReducer from './order/order.slice'
import templateReducer from './template/template.slice'
import stemReducer from './stem/stem.slice'
import productReducer from './product/product.slice'
import eventOrderReducer from './eventOrder/eventOrder.slice'
import hardGoodReducer from './hardGood/hardGood.slice'
import proposalTemplateReducer from './proposalTemplate/template.slide'
import productCatalogReducer from './productCatalog/catalog.slide'
import stemPaletteReducer from './stemPalette/stemPalette.slice'
import notificationTaskReducer from './notificationTask/notificationTask.slice'

const rootReducer = combineReducers({
  app: appReducer,
  order: orderReducer,
  template: templateReducer,
  recipe: recipeReducer,
  stem: stemReducer,
  product: productReducer,
  eventOrder: eventOrderReducer,
  hardGood: hardGoodReducer,
  proposalTemplate: proposalTemplateReducer,
  catalog: productCatalogReducer,
  stemPalette: stemPaletteReducer,
  notificationTask: notificationTaskReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
