import React from 'react'
import { Switch } from 'react-router-dom'
import { PATH_URL } from './common/path'
import { RouteConfig } from './common/route.interface'
import { Navbar } from './components'
import GroupRoutes from './components/GroupRoutes/GroupRoutes'

const routes: RouteConfig[] = [
  {
    path: PATH_URL.ORDER_SUMMARY,
    component: React.lazy(() => import('src/pages/OrderSummary'))
  },
  {
    path: PATH_URL.ADD_PRODUCT(),
    component: React.lazy(() => import('src/pages/AddCustomProduct'))
  },
  {
    path: PATH_URL.CREATE_ORDER(),
    component: React.lazy(() => import('src/pages/CreateOrder'))
  },
  {
    path: PATH_URL.SUPPLIER_ORDER(),
    component: React.lazy(() => import('src/pages/CreateOrder'))
  },
  {
    path: PATH_URL.PROFILE,
    component: React.lazy(() => import('src/pages/Profile'))
  },
  {
    path: PATH_URL.STEMS,
    component: React.lazy(() => import('src/pages/Stems'))
  },
  {
    path: PATH_URL.PRODUCT_DETAIL(),
    component: React.lazy(() => import('src/pages/ProductDetail'))
  },
  {
    path: PATH_URL.REVIEW_PRODUCT_DETAIL(),
    component: React.lazy(() => import('src/pages/ProductDetail'))
  },
  {
    path: PATH_URL.PRODUCTS,
    component: React.lazy(() => import('src/pages/Products'))
  },
  {
    path: PATH_URL.REVIEW_PRODUCTS,
    component: React.lazy(() => import('src/pages/Products'))
  },
  {
    path: PATH_URL.EVENT(),
    component: React.lazy(() => import('src/pages/RecipeBuilder'))
  },
  {
    path: PATH_URL.TEMPLATE,
    component: React.lazy(() => import('src/pages/RecipeTemplate'))
  },
  {
    path: PATH_URL.HOME,
    component: React.lazy(() => import('src/pages/Landing')),
    exact: true
  },
  {
    path: PATH_URL.EVENT_SUMMARY(),
    component: React.lazy(() => import('src/pages/EventSummaryV2'))
  },
  {
    path: PATH_URL.EVENT_SUMMARY_OLD(),
    component: React.lazy(() => import('src/pages/EventSummary'))
  },
  {
    path: PATH_URL.HARD_GOODS,
    component: React.lazy(() => import('src/pages/HardGoods'))
  },
  {
    path: PATH_URL.STYLE_INFORMATION(),
    component: React.lazy(() => import('src/pages/StyleInformation'))
  },
  {
    path: PATH_URL.PROPOSAL_TEMPLATE,
    component: React.lazy(() => import('src/pages/ProposalTemplate')),
    exact: true
  },
  {
    path: PATH_URL.TEMPLATE_PRODUCT(),
    component: React.lazy(() => import('src/pages/TemplateProduct'))
  },
  {
    path: PATH_URL.STEM_PALETTE,
    component: React.lazy(() => import('src/pages/StemPalette')),
    exact: true
  },
  {
    path: PATH_URL.STEM_PALETTE_STEM(),
    component: React.lazy(() => import('src/pages/PaletteStem'))
  },
  {
    path: PATH_URL.NOTIFICATION_TASK,
    component: React.lazy(() => import('src/pages/NotificationTask'))
  },
  {
    path: '*',
    component: React.lazy(() => import('src/pages/NotFoundPage'))
  }
]

export const AppRouter = () => {
  return (
    <div className="bg-image">
      <Navbar />
      <Switch>
        <GroupRoutes routes={routes} />
      </Switch>
    </div>
  )
}
